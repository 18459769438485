//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import FinanaceProcess from "../asset/FinanaceProcess.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
      {/* <h1>금융데이터의 AI 분석을 통한 비즈니스 전략 제안 서비스</h1> */}
      {/* <h1>데이터 기반 금융 의사결정 강화 및 개인화 서비스 제공 솔루션</h1> */}
      <h1>고객 맞춤 금융 상품 추천 AI 솔루션</h1>
        </div>
      <div className={styles.LLMProcessCover}>
        <div className={styles.flexProcess}>
          <div className={styles.flexProcessMent}>
            <h1>시스템구성</h1>
            <ul>
              <div>
                <li>데이터 수집</li>
                <li>데이터 전처리</li>
                <li>데이터 파이프라인 구축</li>
                <li>실시간 데이터 연동</li>
              </div>
              <div>
                <li>데이터 기반 예측 분석</li>
                <li>대시보드 제공</li>
                <li>실시간 상호작용</li>
              </div>
            </ul>
          </div>
        </div>
        <div>
          <div className={`${styles.LLMProccess} ${styles.FNProcess}`}>
            <img src={FinanaceProcess} alt="dd" />
          </div>
        </div>
      </div>
    </div>
  );
}
