//css
import styles from "../css/LLM.module.css"
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={styles.midCover}>
            <h1>CodeSync만의 특별함</h1>
            <p>최첨단 Prompt 최적화 기술과 BNVs가 보유한 사용자 로그 데이터를 <br />활용해 파인튜닝된 생성 AI 기반 코딩 튜터를 제공합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}