//hooks
// import {useState, useEffect, useCallback} from 'react'
//css
import styles from "../css/Company.module.css"
//assets
// import cover1 from "../asset/officepunch.jpg"
// import cover2 from "../asset/cover2.png"
import cover3 from "../asset/cover3.jpg"

export default function CompanyCover() {
    // const coverImg = [cover1, cover2, cover3];
    // const [currImgIndex, setcurrImgIndex] = useState(0);
    // const changeImg = useCallback(() => {
    //     setcurrImgIndex((preIndex)=>(preIndex+1)%coverImg.length);
    // },[coverImg.length]);
    // useEffect(()=>{
    //     const intervalId = setInterval(changeImg, 1500);
    //     return () => clearInterval(intervalId);
    // },[changeImg]);
    return (
        <section className={styles.Company_Visual}>
        <div className={styles.Company_Visual_ment}>
          <h1>
            미래를 선도하는
            <br />
            혁신의 시작점,
            <br />
            BNVs
          </h1>
          <p>
            BNVs는 창의적 사고와 기술 혁신을 바탕으로 <br /> 산업을 선도합니다. <br /><br />
            BNVs는 차별화된 접근 방식으로 고객에게 <br />새로운 가치를 창출합니다.
          </p>
        </div>
        <div className={styles.company_MainImg}>
          {/* <img src={coverImg[currImgIndex]} alt="Img" width="500px" /> */}
          <img src={cover3} alt="Img" width="500px" />
        </div>
      </section>
    )
}