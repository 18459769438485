import styles from "../css/Company.module.css";

export default function CompanyInfo(props) {
  return (
    <div className={styles.CompanyInfos}>
      <h1>{props.subject}</h1>
      <div className={styles.CompanyInfos_Body}>
        <div>
          <ul>
            {props.ment &&
              props.ment.map((item) => (
                <li key={item.key}>
                  <h3>{item.body}</h3>
                  <p>{item.win}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
