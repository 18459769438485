
import { Link } from "react-router-dom";
//css
import styles from "../css/MainSoutions.module.css";
//asset
import {BsArrowRightCircleFill } from "react-icons/bs";
import React from "react";

const MainSolution = (props) => {
    const links = ["LLM-CodeSync","SCM","Data%20Processing"]
    const data = props.props.data;
    return (
        <div className={styles.Solutionscontainer}>
            {data.img.map((item, idx) => (
                <React.Fragment key={idx}>
                    {idx % 2 === 0 ? (
                        <div className={styles.Solution}>
                            <div className={styles.Solution_img}>
                                <img src={item} alt='solution_img' />
                            </div>
                            <div className={styles.Solution_Ment}>
                                <p><strong>{data.title[idx]}</strong></p>
                                <p><strong>{data.subtitle[idx]}</strong></p>
                                <p>
                                    {data.body[idx]}
                                    <Link to={links[idx]} className={styles.goto}>더 알아보기<BsArrowRightCircleFill className={styles.arrow} /></Link>
                                    </p>
                                
                            </div>
                        </div>
                    ) : (
                        <div key={idx} className={styles.SolutionR}>
                            <div className={styles.Solution_Ment}>
                                <p><strong>{data.title[idx]}</strong></p>
                                <p><strong>{data.subtitle[idx]}</strong></p>
                                <p>{data.body[idx]}<Link to={links[idx]} className={styles.goto}>더 알아보기<BsArrowRightCircleFill className={styles.arrow} /></Link></p>
                                
                            </div>
                            <div className={styles.Solution_img}>
                                <img src={item} alt='solution_img' />
                            </div>
                        </div>
                    )}
                
                </React.Fragment>
            ))}
        </div>
    );
};

export { MainSolution };
