//css
import styles from "../css/Company.module.css";


export default function CompanyMid() {

return (
  <section className={styles.Company_Mid}>
    <div className={styles.Company_Mid_Body}>
        <p>
        BNVs는 기술의 힘으로 세상을 긍정적으로 변화시킵니다.

          <br />데이터 기반 의사 결정으로 새로운 가치를 창출하고
          <br />AI 솔루션을 통해 고객의 비전을 현실로 만듭니다.
        </p>
    </div>
  </section>
);
}