//hooks
import { useEffect } from "react";
//library
import { Link } from "react-router-dom";
//css
import styles from "../css/Nav.module.css";
export function NavmenuA(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navFontColor = {
    color: props.isScrolled ? "black" : props.color,
    textDecoration: "none",
  };
  return (
    <li>
      <Link
        to={`/${props.link}`}
        style={navFontColor}
        className={styles.navMenu}
      >
        {props.text}
      </Link>
    </li>
  );
}

export function NavmenuDD(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ul className={styles.DDList}>
      <li className={styles.DDListtitle}>{props.title}</li>
      <div className={styles.DDListsolution}>
        {props.link.map((item) => (
          <li key={item.key}>
            <Link
              to={`/${item.solution}`}
            >
              {item.solutionK}
            </Link>
          </li>
        ))}
      </div>
    </ul>
    
  );
}

export function NavmenuB(props) {
  const navFontColor = {
    color: props.isScrolled ? "black" : props.color,
    textDecoration: "none",
  };
  const btnBackground = {
    backgroundColor: props.isScrolled
      ? props.backgroundColor_
      : props.backgroundColor_,
    border: `${props.border} 1px solid`,
  };

  return (
    <li style={btnBackground}>
      <Link to="/" style={navFontColor} className={styles.navMenu}>
        {props.text}
      </Link>
    </li>
  );
}

export function ContactModal(props) {
  

  const btnBackground = {
    backgroundColor: props.isScrolled
      ? props.backgroundColor_
      : props.backgroundColor_,
    border: `${props.border} 1px solid`,
    cursor: 'pointer',
  };

  return (
    <li style={btnBackground} onClick={props.toggleModal}>
        {props.text}
    </li>
  );
}
