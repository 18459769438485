//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import Medicalflow from "../asset/Medicalflow.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
      {/* <h1>이미지 처리 모델을 활용한 림프절 예측 솔루션</h1> */}
      <h1>고정밀 림프절 분석 및 진단 지원 AI 솔루션</h1>
      {/* <h1>AI 기반 림프절 상태 분석 및 예측 시스템</h1>
      <h1>림프절 건강 예측 및 모니터링 AI 플랫폼</h1> */}
        </div>
      <div className={styles.LLMProcessCover}>
        <div className={styles.flexProcess}>
          <div className={`${styles.flexProcessMent} ${styles.MDProcessMent}`}>
            <h1>시스템구성</h1>
            <ul>
              <div>
                <li>데이터 수집</li>
                <li>데이터 전처리</li>
                <li>모델 개발</li>
                <li>알고리즘 평가</li>
              </div>
              <div>
                <li>모델 검증</li>
                <li>데이터 기반 예측 분석</li>
                <li>실시간 결과 제공</li>
              </div>
            </ul>
          </div>
        </div>
        <div>
          <div className={`${styles.LLMProccess} ${styles.MDProcess}`}>
            <img src={Medicalflow} alt="dd" />
          </div>
        </div>
      </div>
    </div>
  );
}
