//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={styles.midCover}>
            <h1>AI가 주도하는 물류 혁신</h1>
            <p>첨단 데이터 분석 기술을 기반으로 한 맞춤형 서비스 제공으로<br />차별화된 물류 AI 솔루션을 제공합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}