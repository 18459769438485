//css
import styles from "../../CommonFile/LLM.module.css"
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={`${styles.LLMImgCover} ${styles.SCMImgCover}`}>
        <div className={`${styles.LLMImgCoverc} ${styles.SCMImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지"/>
        <div className={`${styles.LLMCoverMent} ${styles.SCMCoverMent}`}>
          <h3>AI로 혁신하는 스마트 물류 네트워크</h3>
          <p>
          AI 기반 유통 및 물류 솔루션은 첨단 데이터 분석을 통해 물류 업계의 디지털 전환을 가속화합니다. 실시간 수요 예측, 재고 최적화, 그리고 효율적인 유통 관리로 업계의 경쟁력을 강화하고, 고객 만족도를 높여줍니다.
          </p>
        </div>
      </div>
      <LLMMid ment={props.ment}/>
    </div>
  );
}
