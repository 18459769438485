import styles from "../css/Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footer_cover}>
      <section className={styles.footer}>
        <div className={styles.footer_left}>
          비앤브이솔루션(주) 서울 마포구 양화로 81, 3층 330
          <br />
          고객센터 : 02-6465-6278 이메일 : gusdnekd47@bnvs.co.kr 대표이사 : 박기범
          <br />
          사업자등록번호: 556-88-02474
          <strong>
            <p>Copyright @ 2023 BNVS. All rights reserved</p>
          </strong>
        </div>
        <ul className={styles.footer_right}>
          <li>
            <a href={"/Policy1"}>서비스 이용 약관</a>
          </li>
          <li>
            <a href={"/Policy2"}>개인정보 처리 방침</a>
          </li>
        </ul>
      </section>
    </div>
  );
}
