//css
import styles from "../css/LLM.module.css";
//asset
import process from "../asset/process.png";
import LLMCarousel from "./LLM_Caroursel";

export default function LLMFlow() {
  return (
    <div className={styles.LLMProcessCover}>
      <div className={styles.flexProcess}>
        <div className={styles.flexProcessMent}>
          <h1>시스템구성</h1>
          <ul>
            <li>데이터 수집 & 생성</li>
            <li>파인튜닝 모델 학습</li>
            <li>학습자 대시보드 제공</li>
          </ul>
        </div>
        <LLMCarousel />
      </div>
      <div className={styles.LLMProccess}>
        <img src={process} alt="dd" />
      </div>
    </div>
  );
}
