//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={`${styles.LLMImgCover} ${styles.DAImgCover}`}>
        <div className={`${styles.LLMImgCoverc} ${styles.DAImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지" />
        <div className={`${styles.LLMCoverMent} ${styles.DACoverMent}`}>
          {/* <img src={props.cdlogo} alt="배경 로고" className={styles.coverLogo} />
          &nbsp;<span>코드싱크</span> */}
          <h3>LLM모델 파인튜닝용<br/> 데이터 생성 서비스</h3>
          <p>
            데이터 생성 서비스는 다양한 도메인에 적용 가능한 LLM 모델을 위한 파인튜닝 데이터를 생성합니다.
            이를 통해 고객이 원하는 응답을 제공하는 맞춤형 AI 모델을 개발할 수 있습니다.
          </p>
          {/* <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>Demo 보러가기 &nbsp; &gt;</a>
          </div> */}
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}

