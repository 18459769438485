//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import process from "../asset/PreprocessingProcess.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
      <h1>심층 인사이트 데이터 가공 및 분석 솔루션</h1>
        </div>
      <div className={styles.LLMProcessCover}>
        <div className={styles.flexProcess}>
          <div className={styles.flexProcessMent}>
            <h1>시스템구성</h1>
            <ul>
              <div>
                <li>데이터 수집</li>
                <li>데이터 정제</li>
                <li>데이터 변환 및 통합</li>
              </div>
              <div>
                <li>특성 추출 및 선택</li>
                <li>AI 분석</li>
                <li>결과 활용 데이터 생성</li>
              </div>
            </ul>
          </div>
        </div>
        <div>
          <div className={styles.LLMProccess}>
            <img src={process} alt="dd" />
          </div>
        </div>
      </div>
    </div>
  );
}
