//hooks
// import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import ProssesingCover from "./asset/ProssesingCover.png";
// import CodeSyncLogo from "./asset/CodeSyncLogo2.png";
// import LLMDemoVod from "./components/LLM_Demo_vod";
import LLMFlow from './components/LLMFlow';
import { MdInsights } from "react-icons/md";
import { GrGroup } from "react-icons/gr";
import { LiaCheckSquareSolid } from "react-icons/lia";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";


export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "심층 데이터 인사이트",
      body1: "복잡한 데이터 세트에서 ",
      body2: "중요한 인사이트를 추출",
      icon :() => <MdInsights />,
    },
    {
      index: "point 02.",
      point: "맞춤형 데이터 솔루션",
      body1: "고객 맞춤 데이터 가공",
      body2: "고객 맞춤형 솔루션 제공",
      icon: () => <GrGroup />,
    },
    {
      index: "point 03.",
      point: "효율적인 데이터 처리",
      body1: "데이터 처리 기술 활용해",
      body2: "회사의 가치있는 데이터 활용 지원",
      icon : () => <LiaCheckSquareSolid />,
    },
    {
      index: "point 04.",
      point: "다목적 데이터 활용",
      body1: "마케팅, 제품개발 등 다양한",
      body2: "분야에서의 데이터 활용",
      icon : () => <MdOutlineProductionQuantityLimits />
    },
  ];
  // const demoVodRef = useRef(null);
  // const scrollToDemoVod = () => {
  //   demoVodRef.current.scrollIntoView({behavior: 'smooth'})
  // };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={ProssesingCover} />
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

