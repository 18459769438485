//hooks
// import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import AugCover from "./asset/AugCover.png";
// import CodeSyncLogo from "./asset/CodeSyncLogo2.png";
// import LLMDemoVod from "./components/LLM_Demo_vod";
import LLMFlow from './components/LLMFlow';
import { GrGroup } from "react-icons/gr";
import { SiGoogleoptimize } from "react-icons/si";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { SiStimulus } from "react-icons/si";


export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "맞춤형 데이터 생성",
      body1: "고객사의 도메인 요구사항에",
      body2: "맞춰 특화된 데이터 제작",
      icon :() => <GrGroup />,
    },
    {
      index: "point 02.",
      point: "AI 기반 데이터 최적화",
      body1: "AI활용 데이터 최적화를 통해",
      body2: "데이터 품질과 유용성 향상",
      icon: () => <SiGoogleoptimize />,
    },
    {
      index: "point 03.",
      point: "보안 및 프라이버시 관리",
      body1: "데이터 생성 과정에서 민감정보와",
      body2: "개인식별 정보의 보안 관리",
      icon : () => <MdOutlinePrivacyTip />,
    },
    {
      index: "point 04.",
      point: "데이터 활용성 및 가치 극대화",
      body1: "고객사의 데이터 기반",
      body2: "의사결정 촉진",
      icon : () => <SiStimulus />
    },
  ];
  // const demoVodRef = useRef(null);
  // const scrollToDemoVod = () => {
  //   demoVodRef.current.scrollIntoView({behavior: 'smooth'})
  // };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={AugCover} />
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

