//hooks
// import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import SCMCover from "./asset/SCMCover.png";
import LLMFlow from './components/LLMFlow';
// import LLMDemoVod from "./components/LLM_Demo_vod";
import { VscGraph } from "react-icons/vsc";
import { CiBoxes } from "react-icons/ci";
import { TbRoute } from "react-icons/tb";
import { MdOutlineSettingsSystemDaydream } from "react-icons/md";
export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "실시간 수요 예측",
      body1: "정교한 데이터 분석을 통한",
      body2: "정확한 수요 예측 및 재고 관리",
      icon :() => <VscGraph />,
    },
    {
      index: "point 02.",
      point: "스마트 재고 최적화",
      body1: "AI 기반 분석으로",
      body2: "효율적인 재고 관리 및 비용 절감",
      icon: () => <CiBoxes />,
    },
    {
      index: "point 03.",
      point: "효율적인 유통 경로 계획",
      body1: "고급 알고리즘을 활용한",
      body2: "최적의 유통 경로 설정",
      icon : () => <TbRoute />,
    },
    {
      index: "point 04.",
      point: "통합 물류 관리 시스템",
      body1: "직관적인 사용자 인터페이스와",
      body2: "통합된 데이터 관리",
      icon : () => <MdOutlineSettingsSystemDaydream />
    },
  ];
//   const demoVodRef = useRef(null);
//   const scrollToDemoVod = () => {
//     demoVodRef.current.scrollIntoView({behavior: 'smooth'})
//   };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={SCMCover} />
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

