//hooks
// import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import MedicalCover from "./asset/MedicalCover.png";
// import CodeSyncLogo from "./asset/CodeSyncLogo2.png";
// import LLMDemoVod from "./components/LLM_Demo_vod";
import LLMFlow from './components/LLMFlow';
import { GrVirtualMachine } from "react-icons/gr";
import { GrGroup } from "react-icons/gr";
import { LiaCheckSquareSolid } from "react-icons/lia";
import { MdOutlineIntegrationInstructions } from "react-icons/md";



export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "지능형 데이터 분석",
      body1: "방대한 의료 데이터 활용한",
      body2: "질병의 조기 발견 및 정밀 진단",
      icon :() => <GrVirtualMachine />,
    },
    {
      index: "point 02.",
      point: "맞춤형 서비스 제공",
      body1: "환자 개인의 데이터를 기반으로",
      body2: "맞춤형 진단 및 치료 제안",
      icon: () => <GrGroup />,
    },
    {
      index: "point 03.",
      point: "예측진단",
      body1: "과거 데이터와 패턴 인식을 통해",
      body2: "잠재적 건강 위험 및 상태 예측",
      icon : () => <LiaCheckSquareSolid />,
    },
    {
      index: "point 04.",
      point: "협업 데이터 통합",
      body1: "의료 장비로 부터의 입력을",
      body2: "결합하여 종합적인 건강 개요 제공",
      icon : () => <MdOutlineIntegrationInstructions />
    },
  ];
  // const demoVodRef = useRef(null);
  // const scrollToDemoVod = () => {
  //   demoVodRef.current.scrollIntoView({behavior: 'smooth'})
  // };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={MedicalCover} />
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

