//hooks

//components
import Nav from "../../common/components/Nav";
import CompanyCover from "./components/Company_cover";
import Footer from "../../common/components/Footer";
//css
import styles from "./css/Company.module.css";
//assets
import CompanyMid from "./components/Company_mid";
import CompanyBottom from "./components/Company_bottom";

export default function Company() {
  return (
    <div className={styles.Company_Cover}>
      <Nav backgroundColor="transparent" color="black" btcolor="#cfcfcf" />
      <CompanyCover />
      <CompanyMid />

      <CompanyBottom />

      <Footer />
    </div>
  );
}
