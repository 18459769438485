//hooks
import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
//css
import styles from "../css/Modal.module.css";
import * as yup from "yup";
//components
import ModalIcon from "./ModalIcon";
//asset
import locaIcon from "../asset/icon2.svg";
import phoneIcon from "../asset/icon3.svg";
import emailIcon from "../asset/icon1.svg";
import { useContext } from "react";
import { MainContext } from "../../App";
import { useState } from "react";


const postFetch = async (url, datas) => {
  
  try {
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datas),
    });
    if (!response.ok) {
      throw new Error("서버에 오류가 생겼습니다.");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err);
  }
};


export function Modal({ closeModal }) {
  const { backendPath } = useContext(MainContext)
  const [ isClose,setIsClose ] = useState(false)
  const cttData = [
    {
      key: 1,
      img:  locaIcon ,
      title: "Address",
      body: "서울특별시 마포구 양화로 81, 3층 330호",
    },
    {
      key: 2,
      img:  phoneIcon ,
      title: "Phone",
      body: "+82 2-6465-6278",
    },
    {
      key: 3,
      img:  emailIcon ,
      title: "Email",
      body: "gusdnekd47@bnvs.co.kr",
    },
  ];
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) closeModal();
    };
    window.addEventListener("keydown", handleEsc);
    if (isClose) {
      setIsClose(false)
      closeModal()
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [closeModal,isClose]);


  const schema = yup.object({
    first_name: yup.string().max(5, "성은 최대5글자 입니다."),
    last_name: yup.string().max(10, "이름은 최대10글자 입니다."),
    email: yup
      .string()
      .email("이메일 형식을 지켜주세요")
      .required("* 이메일을 입력해 주세요"),
    contact_number: yup.string(),
    messages: yup
      .string()
      .min(10, "* 문의 내용은 최소 10글자 이상을 넘겨주세요")
      .required("문의 내용은 필수 사항입니다."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const url = `${backendPath}/contact/send`;
      const datas = await postFetch(url, data);
      console.log(datas)
      alert("문의 주셔서 감사합니다.")
      setIsClose(true)
    }
    catch (err) {
      throw new Error(err)
    }
  
  };


  return (
    <div className={styles.modaloverlay} onClick={closeModal}>
      <div
        className={styles.modalcontainer}
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={closeModal} className={styles.modalExitbtn}>
          X
        </button>
        <div className={styles.contactcont}>
          <div className={styles.icon}>
            <ModalIcon data={cttData}/>
          </div>
          <div className={styles.line}></div>
          <div>
            <div className={styles.formMent}>
              <h1>문의하기</h1>
              <p>
                지금 상담을 요청해 보세요!< br/>실시간으로 다양한 고객사들이 BNVs의 솔루션을 적용해 비즈니스를 확장하고 있습니다.
              </p>
            </div>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.flex}>
                <label>
                  <input
                    required=""
                    placeholder="성"
                    type="text"
                    className={styles.input}
                    {...register("first_name")}
                  />
                  {errors.first_name?.message && (
                    <p>{errors.first_name.message}</p>
                  )}
                </label>
                <label>
                  <input
                    required=""
                    placeholder="이름"
                    type="text"
                    className={styles.input}
                    {...register("last_name")}
                  />
                  {errors.last_name?.message && (
                    <p>{errors.last_name.message}</p>
                  )}
                  
                </label>
              </div>
              <label>
                <input
                  required=""
                  placeholder="메일 *"
                  type="email"
                  className={styles.input}
                  {...register("email")}
                />
                {errors.email?.message && <p>{errors.email.message}</p>}
              </label>
              {/* <label>
                <input
                  required=""
                  type="tel"
                  placeholder=""
                  className={styles.input}
                  {...register("contact_number")}
                />
                {errors.contact_number?.message && (
                  <p>{errors.contact_number.message}</p>
                )}
                <span>전화번호</span>
              </label> */}
              {/* <span className={styles.Modalmsg}></span> */}
              <label>
                <textarea
                  required=""
                  rows="3"
                  placeholder=""
                  className={styles.input01}
                  {...register("messages")}
                ></textarea>
                {errors.messages?.message && <p>{errors.messages.message}</p>}
              </label>
              <div style={{
                display:'flex',
                justifyContent:'flex-end'
              }}><input className={styles.text} type="submit" value="제출" /></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
