//module
import { useEffect, useState } from "react";
//component
import {BsArrowRightCircleFill } from "react-icons/bs";
//css
import styles from "../css/Company.module.css";

export default function CompanyInfoVod(props) {
  // 선택된 hoverVod 요소의 키를 저장하기 위한 상태
  const [selectedVodKey, setSelectedVodKey] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // hoverVod 요소를 클릭할 때 호출될 이벤트 핸들러
  const handleVodClick = (key) => {
    // 이미 선택된 요소를 다시 클릭하면 높이를 0으로 설정
    if (selectedVodKey === key) {
      setSelectedVodKey(null);
    } else {
      setSelectedVodKey(key);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(()=>{
    const handleScroll = ()=>{
      const scrollPosition = window.scrollY;
      const windowWidth = window.innerWidth;

      if (windowWidth <= 767) {
        return ;
      } else if (windowWidth <= 1023) {
        setIsSticky(scrollPosition > 1000 && scrollPosition <1905);
      } else {
        setIsSticky(1600 <scrollPosition && scrollPosition < 2800);
      }
    
    };
    window.addEventListener("scroll",handleScroll);
    return () => {
      window.removeEventListener("scroll",handleScroll);
    };
  },[])
  return (
    <div className={styles.CompanyInfos}>
      {isSticky && <div className={styles.dummyHeader}></div>}
      <h1 className={`${styles.fixH1} ${isSticky ? styles.stickyH1 : ""}`}>{props.subject} <span>Click <BsArrowRightCircleFill className={styles.arrow}/></span></h1>
      <div className={styles.CompanyInfos_Body}>
        <div>
          <ul>
            {props.ment &&
              props.ment.map((item) => (
                <li
                  key={item.key}
                  className={styles.hoverVod}
                  onClick={() => handleVodClick(item.key)}
                >
                  <div className={styles.hoverFont}>
                    <h3>{item.body}</h3>
                    <p>{item.win}</p>
                  </div>
                  <div
                    className={`${styles.CurrentVideo} ${
                      selectedVodKey === item.key ? "active" : ""
                    }`}
                  >
                    <iframe
                      className={styles.iframe}
                      src={`https://www.youtube.com/embed/${item.video}`}
                      width="700"
                      height={selectedVodKey === item.key ? (windowWidth <= 767 ? "180" :"400") : "0"}
                      frameBorder="0"
                      allowFullScreen
                      title={`YouTube Video ${item.body}`}
                    ></iframe>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
