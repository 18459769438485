//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={`${styles.midCover} ${styles.DPMidCover}`}>
            <h1>데이터 기반 경영을 위한 데이터 가공 서비스</h1>
            <p>첨단 데이터 가공 기술을 기반으로 고객이 데이터를 가치있게 활용하도록 합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}