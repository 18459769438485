import styles from "../css/Modal.module.css"

const ModalIcon = (props) => {
    console.log(props.data)
    return (
        <div className={styles.iconsCont}>
            {props.data.map((item)=>{
                return (
                    <div className={styles.icons} key={item.key}>
                    <img src={item.img} alt="img" width={35}></img>
                    <p>{item.title}</p>
                    <ul>
                        <li>{item.body}</li>
                    </ul>
                </div>
                )
            })}

        </div>
    )
}
export default ModalIcon