//router
import { BrowserRouter, Route, Routes } from "react-router-dom";
//components
import Main from "./pages/Main/Main";
import Company from "./pages/Company/Company";
import LLM from "./pages/Solutions/LLM/LLM";
import DataAugmentation from "./pages/Solutions/Data_Augmentation/Data_Augmentation";
import DataProcessing from "./pages/Solutions/Data_Processing/Data_Processing";
import Finance from "./pages/Solutions/Finance/Finance";
import Manufactoring from "./pages/Solutions/Manufactoring/Manufactoring";
import Medical from "./pages/Solutions/Medical/Medical";
import SCM from "./pages/Solutions/SCM/SCM";
import { createContext } from "react";
import Policy1 from "./pages/Policy/Policy1";
import Policy2 from "./pages/Policy/Policy2";

export const MainContext = createContext()

function App() {
  const backendPath = process.env.REACT_APP_BACKEND_HOST
  return (
    <MainContext.Provider value={{backendPath}}>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/company" element={<Company />} />
            <Route path="/LLM-CodeSync" element={<LLM />} />
            <Route path="/SCM" element={<SCM />} />
            <Route path="/Medical" element={<Medical />} />
            <Route path="/Manufactoring" element={<Manufactoring />} />
            <Route path="/Finance" element={<Finance />} />
            <Route path="/Data Processing" element={<DataProcessing />} />
            <Route path="/Data Augmentation" element={<DataAugmentation />} />
            <Route path="/Policy1" element={<Policy1 />} />
            <Route path="/Policy2" element={<Policy2 />} />
          </Routes>
        </div>
      </BrowserRouter>
    </MainContext.Provider>
  );
}

export default App;