//hooks
// import {useRef} from 'react'
//components
import Footer from "../../common/components/Footer";
import Nav from "../../common/components/Nav";
//css
import styles from "./Policy.module.css";

//서비스 이용약관
export default function Policy2() {
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <div className={styles.policyCover}>
        <div className={styles.policyCoverc}>
          <div className={styles.policyTitle}>
            <h1>BNVs</h1>
            <p>개인정보&nbsp;취급방침</p>
          </div>
          <div className={styles.line}></div>
          <div className={styles.PolicyBody}>
            <p>개인정보 취급방침</p>
            <p>
              &lsquo;비앤브이솔루션&rsquo;은 (이하 &lsquo;회사&rsquo;) 이용자의
              개인정보를 중요시하며, &ldquo;정보통신망 이용촉진 및
              정보보호&rdquo;에 관한 법률을 준수하고 있습니다. 회사는
              개인정보취급방침을 통하여 이용자가 제공하는 개인정보가 어떠한
              용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
              취해지고 있는지 알려드립니다. 만일 개인정보취급방침을 개정하는
              경우에는 웹사이트 공지사항(또는 개별공지)을 통하여 안내해
              드립니다. 본 방침은 2023년 10월 08일부터 시행됩니다.
            </p>
            <p>[개인정보 수집 항목]</p>
            <p>
              회사는 상담, 서비스 신청을 위해 아래와 같은 개인정보를 수집하고
              있습니다.&nbsp;
            </p>
            <ol>
              <li>
                수집항목 : 이름, 연락처, 이메일, 소속 또한 서비스 이용과정이나
                사업처리과정에서 접속로그, 쿠키, IP정보 등이 생성되어 수집될 수
                있습니다.&nbsp;
              </li>
              <li>개인정보 수집방법 : 홈페이지 내 문의란</li>
            </ol>
            <p>&nbsp;</p>
            <p>[개인정보의 수집 목적]</p>
            <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.&nbsp;</p>
            <ol>
              <li>서비스 및 제품 문의, 상담 문의</li>
              <li>
                회원 관리(본인확인, 개인식별, 부정이용 방지와 비인가 사용 방지,
                가입의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달)
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>[개인정보의 보유 및 이용기간]</p>
            <p>
              이용자가 회사에서 제공하는 서비스를 이용하는 동안 회사는 이용자의
              개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다.
              이용자가 직접 회원탈퇴를 신청하시거나 개인정보 삭제를 요청한 경우
              추후 열람이나 이용이 불가능한 상태로 처리됩니다. 다만, 회사는 불량
              회원의 부정한 이용의 재발을 방지하기 위해 해지 요청일로부터 2년간
              해당 회원의 아이디, 이름, 주민등록번호, 이메일을 보유할 수
              있습니다.&nbsp;
            </p>
            <ol>
              <li>
                관련 법령에 의한 정보보유 및 이용기간 상법, 전자상거래 등에서의
                소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
                있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를
                보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만
                이용하며 보존기간은 아래와 같습니다.
              </li>
            </ol>
            <p>1) 계약 또는 청약철회 등에 관한 기록</p>
            <p>
              &nbsp;- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            </p>
            <p>&nbsp;- 보존 기간 : 5년</p>
            <p>2) 대금결제 및 재화 등의 공급에 관한 기록</p>
            <p>
              &nbsp;- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            </p>
            <p>&nbsp;- 보존 기간 : 5년</p>
            <p>3) 소비자의 불만 또는 분쟁처리에 관한 기록</p>
            <p>
              &nbsp;- 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            </p>
            <p>&nbsp;- 보존 기간 : 3년</p>
            <p>4) 본인확인에 관한 기록</p>
            <p>
              &nbsp;- 보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
            </p>
            <p>&nbsp;- 보존 기간 : 6개월</p>
            <p>5) 방문에 관한 기록</p>
            <p>&nbsp;- 보존 이유 : 통신비밀보호법</p>
            <p>&nbsp;- 보존 기간 : 3개월</p>
            <p>&nbsp;</p>
            <p>[개인정보의 파기 절차 및 방법]</p>
            <p>
              회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
              정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
            </p>
            <ol>
              <li>
                전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
                방법을 사용하여 삭제합니다.
              </li>
              <li>
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                파기합니다.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>[개인정보의 제 3자 제공]</p>
            <p>
              회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
              다만, 아래의 경우에는 예외로 합니다.&nbsp;
            </p>
            <ol>
              <li>이용자가 사전에 동의한 경우&nbsp;</li>
              <li>
                법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
                방법에 따라 수사기관의 요구가 있는 경우
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>[개인정보의 기술적, 관리적 보호조치]</p>
            <p>
              회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에
              필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
            </p>
            <ol>
              <li>개인정보 취급 직원의 최소화 및 교육.</li>
              <li>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화
                하고 개인정보를 관리하는 대책을 시행하고 있습니다.
              </li>
            </ol>
            <ol>
              <li>내부 관리계획의 수립 및 시행</li>
            </ol>
            <ul>
              <li>
                개인정보의 안전한 처리를 위하여 내부 관리계획을 수립, 시행하고
                있습니다.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
              <li>&nbsp;해킹 등에 대비한 기술적 대책</li>
            </ol>
            <ul>
              <li>
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신, 점검을
                하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적,
                물리적으로 감시 및 차단하고 있습니다.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
              <li>&nbsp;개인정보에 대한 접근 제한</li>
            </ol>
            <ul>
              <li>
                개인정보를 처리하는 데이터베이스 시스템에 대한 접근권한의 부여,
                변경, 말소를 통하여 개인정보에 대한 접근통제를 위해 필요한
                조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
                접근을 통제하고 있습니다.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
              <li>&nbsp;비인가자에 대한 출입 통제</li>
            </ol>
            <ul>
              <li>
                &nbsp;개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고
                이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>[개인정보에 관한 민원서비스]</p>
            <ol style={{
              listStyle: "square",
            }}>
              <li>
                이용자는 회사의 홈페이지를 통해 언제든지 등록되어 있는 자신의
                개인정보를 조회, 수정할 수 있으며 가입탈퇴를 요청할 수
                있습니다.&nbsp;
              </li>
              <li>
                회원ID나 비밀번호를 확인하시려면 관리자에게 e-mail을 통해 확인을
                요청할 수 있습니다.&nbsp;
              </li>
              <li>
                회원가입 등을 통해 개인정보 수집, 이용, 제공에 대해 귀하께서
                동의하신 내용을 언제든지 철회하실 수 있습니다.&nbsp;
              </li>
              <li>
                회사는 개인정보 수집에 대한 동의 철회(회원탈퇴)를 개인정보 수집
                시와 동등한 방법 및 절차에 따라 행사할 수 있도록
                하겠습니다.&nbsp;
              </li>
              <li>
                회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을
                처리하기 위하여 아래와 같이 개인정보책임자, 개인정보처리자를
                지정하고 있습니다.
              </li>
            </ol>
            <p> 개인정보 책임자 : 정슬기</p>
            <p> 전화번호 : 02-6465-6278</p>
            <p>
              {" "}
              이메일 :{" "}
              <a href="mailto:tmfrl98@bnvs.co.kr">tmfrl98@bnvs.co.kr</a>
            </p>
            <ol start="6">
              <li>
                이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
                관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수
                있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한
                답변을 드릴 것입니다.&nbsp;
              </li>
              <li>
                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                기관에 문의하시기 바랍니다.
              </li>
            </ol>
            <p>1) 개인분쟁조정위원회 (www.1336.or.kr/1336)</p>
            <p>2) 정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)</p>
            <p>
              3) 대검찰청 인터넷범죄수사센터
              (http://icic.sppo.go.kr/02-3480-3600)
            </p>
            <p>4) 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</p>
            <p>
              <br />
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
