//css
import styles from "../css/LLM.module.css";
import LLMMid from "./LLM_Mid";
import { HiOutlineCursorClick } from "react-icons/hi";

export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={styles.LLMImgCover}>
        <div className={styles.LLMImgCoverc}></div>
        <img src={props.cover} alt="배경 이미지" className={styles.CoverIMG}/>
        <div className={styles.LLMCoverMent}>
          <img
            src={props.cdlogo}
            alt="배경 로고"
            className={styles.coverLogo}
          />
          &nbsp;<span>코드싱크</span>
          <h3>혁신적인 코드교육을 위한 생성형 언어 모델 'CodeSync'</h3>
          <p>
            BNVs의 생성형AI <strong>CodeSync</strong>는 단순한 질의 응답을 넘어
            사용자에게 필요한 학습내용을 AI기반으로 예측하고 제공함으로써,
            학습에 최적화된 사용자 경험을 창출합니다.
          </p>
          <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>
              <span>Demo 보러가기</span> <HiOutlineCursorClick/>
            </a>
          </div>
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}
