//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={`${styles.midCover} ${styles.asdf}`}>
            <h1>제조업의 지능화를 이끄는 AI 기술</h1>
            <p>정교한 데이터 분석과 머신러닝을 통해<br />맞춤형 제조 솔루션을 제공합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}