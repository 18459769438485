//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={`${styles.LLMImgCover} ${styles.DPImgCover}`}>
        <div className={`${styles.LLMImgCoverc} ${styles.DPImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지" />
        <div className={`${styles.LLMCoverMent} ${styles.DPCoverMent}`}>
          {/* <img src={props.cdlogo} alt="배경 로고" className={styles.coverLogo} />
          &nbsp;<span>코드싱크</span> */}
          <h3>데이터 가공 서비스</h3>
          <p>
            데이터 가공 서비스는 고객사의 활용되지 않은 데이터를 가치 있는 정보로 가공합니다. 데이터 전처리와 AI 분석을 통해 정형 및 비정형 데이터를 효과적으로
            처리하여 고객사 내부 데이터 및 외부 수집 데이터에서 인사이트를 찾아냅니다.
          </p>
          {/* <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>Demo 보러가기 &nbsp; &gt;</a>
          </div> */}
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}

