//css
import styles from "../../CommonFile/LLM.module.css";

export default function LLMMidCard(props) {
  return (
    <div className={styles.LLMMidCard}>
      <div className={styles.LLMMidCardMent}>
        <h5>{props.ment.index}</h5>
        <h2>{props.ment.point}</h2>
        <div>{props.ment.body1}</div>
        <div>{props.ment.body2}</div>
        <div className={styles.OpenDetail}>{props.ment.icon()}</div>
      </div>
      <div className={styles.CardDetail}></div>
    </div>
  );
}
