//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import manufacFlow from "../asset/manufacFlow.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
        {/* <h1>반도체 EDS공정 불량검출 프로세스 자동화</h1>
        <h1>반도체 제조 최적화 EDS 불량 탐지 AI 솔루션</h1> */}
        <h1>AI 기반 EDS 공정 품질 관리 솔루션</h1>
      </div>
      <div className={styles.LLMProcessCover}>
        <div className={styles.flexProcess}>
          <div className={styles.flexProcessMent}>
            <h1>시스템 구성</h1>
            <ul>
              <div>
                <li>데이터 수집·관리</li>
                <li>데이터 전처리</li>
                <li>모델개발</li>
                <li>알고리즘 평가</li>
              </div>
              <div>
                <li>모델 검증</li>
                <li>시스템 통합</li>
                <li>사용자 인터페이스 설계</li>
                <li>고객사 기술 지원</li>
              </div>
            </ul>
          </div>
        </div>
        <div>
          <div className={styles.LLMProccess}>
            <img src={manufacFlow} alt="dd" />
          </div>
        </div>
      </div>
    </div>
  );
}
