//hooks
import React, { useState, useEffect } from "react";
//component
import { ContactModal, NavmenuA } from "./Navmenu";
import NavDropdown from "./NavDropdown";
import { Modal } from "./Modal";
//css
import styles from "../css/Nav.module.css";
//IMG
import NavToggle from "../asset/NavToggle.png"
// import bnvs_logo from "../asset/bnvs_logo.png";
import bnvs_logo2 from "../asset/bnvs_logo2.png"

export default function Nav(props) {
  const links = {
    link: ["", "company", "solutions"],
    title: ["생성형 AI 솔루션", "산업별 AI 솔루션", "데이터 서비스"],
    solutions:[{solutions1: [{ key: 1, solution: "LLM-CodeSync" ,solutionK:"코드싱크" }]},
    {solutions2: [
      { key: 2, solution: "SCM" ,solutionK:"물류"},
      { key: 3, solution: "Medical" ,solutionK:"의료"},
      { key: 4, solution: "Manufactoring",solutionK:"제조" },
      { key: 5, solution: "Finance",solutionK:"금융" },
    ]},
    {solutions3: [
      { key: 1, solution: "Data Processing" ,solutionK:"데이터 가공"},
      { key: 2, solution: "Data Augmentation" ,solutionK:"데이터 생성" },
    ]}],
  };
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const style = {
    backgroundColor: isScrolled ? "white" : props.backgroundColor,
    transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <div style={style} className={styles.navbar_cover}>
      <div className={styles.navbar}>
        <div>
          <a href="/">
            <img src={bnvs_logo2} alt="logo" width="130" />
          </a>
        </div>
        <div
          className={`${styles.menulist} ${
            isMenuVisible ? styles.showMenu : ""
          }`}
        >
          <ul className={styles.menulistA}>
            <NavmenuA
              isScrolled={isScrolled}
              text="메인"
              link={links.link[0]}
              color={props.color}
            />
            <NavmenuA
              isScrolled={isScrolled}
              text="회사"
              link={links.link[1]}
              color={props.color}
            />
            <NavDropdown
              text="솔루션"
              color={props.color}
              link={links.solutions}
              title={links.title}
            />
          </ul>
          <ul className={styles.menulistB}>
            <ContactModal
              toggleModal={toggleModal}
              isScrolled={isScrolled}
              text="문의하기"
              backgroundColor="#dfe4ec"
              color={props.color}
            />
            {/* <NavmenuB
              isScrolled={isScrolled}
              text="샘플다운로드"
              color={props.color}
            /> */}
          </ul>
        </div>
        <button onClick={toggleMenu} className={styles.nav_toggle_btn}>
         <img src={NavToggle} alt="togglebtn" className={styles.nav_toggle}/>
        </button>
      </div>
      {isModalOpen && <Modal closeModal={toggleModal} />}
    </div>
  );
}
