//hooks
import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import LLMCoverimg from "./asset/LLM_Cover.png";
import CodeSyncLogo from "./asset/CodeSyncLogo2.png";
import LLMDemoVod from "./components/LLM_Demo_vod";
import LLMFlow from './components/LLMFlow';
import { GrRobot } from "react-icons/gr";
import { GrGroup } from "react-icons/gr";
import { AiOutlineInteraction } from "react-icons/ai";
import { TbHeartRateMonitor } from "react-icons/tb";

export default function LLM() {
  // const midMent = [
  //   {
  //     index: "point 01.",
  //     point: "AI 주도 학습",
  //     body1: "사용자의 복잡한 요청이",
  //     body2: "필요없는 학습 진행",
  //     icon :() => <GrRobot />,
  //   },
  //   {
  //     index: "point 02.",
  //     point: "사용자 맞춤",
  //     body1: "학습자 패턴분석 기반",
  //     body2: "개인화된 학습 경로 제공",
  //     icon: () => <GrGroup />,
  //   },
  //   {
  //     index: "point 03.",
  //     point: "인터렉티브 러닝",
  //     body1: "실시간 피드백을 통한",
  //     body2: "사용자와의 양방향 커뮤니케이션",
  //     icon : () => <AiOutlineInteraction />,
  //   },
  //   {
  //     index: "point 04.",
  //     point: "올인원 인터페이스",
  //     body1: "코딩 에디터, 콘텐츠,",
  //     body2: "질의응답 통합 플랫폼",
  //     icon : () => <TbHeartRateMonitor />
  //   },
  // ];
  const midMent = [
    {
      index: "point 01.",
      point: "올인원 인터페이스",
      body1: "코딩 에디터, 콘텐츠,",
      body2: "질의응답 통합 플랫폼",
      icon :() => <GrRobot />,
    },
    {
      index: "point 02.",
      point: "AI 주도 학습",
      body1: "실제 선생님처럼 주도적으로",
      body2: "몰입감있는 수업을 진행",
      icon: () => <GrGroup />,
    },
    {
      index: "point 03.",
      point: "대화형 AI 튜터링",
      body1: "실시간으로 대화를 이해하여",
      body2: "사람같은 커뮤니케이션 가능",
      icon : () => <AiOutlineInteraction />,
    },
    {
      index: "point 04.",
      point: "실시간 맞춤형 피드백",
      body1: "실시간 피드백을 통한",
      body2: "사용자와의 양방향 커뮤니케이션",
      icon : () => <TbHeartRateMonitor />
    },
  ];
  const demoVodRef = useRef(null);
  const scrollToDemoVod = () => {
    demoVodRef.current.scrollIntoView({behavior: 'smooth'})
  };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={LLMCoverimg} cdlogo={CodeSyncLogo} onDemoClick={scrollToDemoVod}/>
      <LLMDemoVod ref={demoVodRef}/>
      <LLMFlow />
      <Footer />
    </div>
  );
}

