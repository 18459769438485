//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={styles.LLMImgCover}>
        <div className={`${styles.LLMImgCoverc} ${styles.MFImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지" />
        <div className={`${styles.LLMCoverMent} ${styles.MFCoverMent}`}>
          <h3>제조업 혁신을 위한 AI 솔루션</h3>
          <p>
            우리의 AI 솔루션은 첨단 데이터 분석과 머신러닝 모델을 활용하여 제조
            공정의 효율성을 극대화합니다. 반도체 EDS 공정에서 불량 검출을 통해
            제조업의 디지털 전환을 선도하고, 생산 효율성과 품질 관리의 새로운
            기준을 제시합니다.
          </p>
          {/* <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>Demo 보러가기 &nbsp; &gt;</a>
          </div> */}
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}
