//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import process from "../asset/AugmentationProcess.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
      <h1>맞춤형 AI 모델 개발을 위한 데이터 생성 및 최적화 서비스</h1>
        </div>
      <div className={styles.LLMProcessCover}>
        <div className={styles.flexProcess}>
          <div className={styles.flexProcessMent}>
            <h1>시스템구성</h1>
            <ul>
              <div>
                <li>도메인 데이터 수집</li>
                <li>외부 데이터 수집</li>
                <li>데이터 전처리</li>
              </div>
              <div>
                <li>데이터 검증</li>
                <li>데이터 생성</li>
                <li>데이터셋 구축</li>
              </div>
            </ul>
          </div>
        </div>
        <div>
          <div className={styles.LLMProccess}>
            <img src={process} alt="dd" />
          </div>
        </div>
      </div>
    </div>
  );
}
