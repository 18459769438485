//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={`${styles.LLMImgCover} ${styles.MDImgCover}`}>
      <div className={`${styles.LLMImgCoverc} ${styles.MDImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지" />
        <div className={`${styles.LLMCoverMent} ${styles.MDCoverMent}`}>
          {/* <img src={props.cdlogo} alt="배경 로고" className={styles.coverLogo} />
          &nbsp;<span>코드싱크</span> */}
          <h3>의료 진단을 위한 AI솔루션</h3>
          <p>
            의료 AI 솔루션은 첨단 머신러닝 알고리즘과 심층 학습 기술을 활용해
            대규모 의료 데이터를 분석하여 질병의 조기 발견 및 정확한 진단을 가능하게 합니다.
          </p>
          {/* <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>Demo 보러가기 &nbsp; &gt;</a>
          </div> */}
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}

