//hooks
import { useState, useEffect } from "react";
//css
import styles from "../css/LLMCrs.module.css";
//assets
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import crs1 from "../asset/LLMc1.png";
import crs2 from "../asset/LLMc2.png";
import crs3 from "../asset/LLMc3.png";
import crs4 from "../asset/LLMc4.png";
import crs5 from "../asset/LLMc5.png";


const LLMCarousel = () => {
  const crsList = [crs1, crs5, crs2, crs3, crs4];
  const [currCarousel, setCurrCarousel] = useState(1);
  const [carouselTransition, setCarouselTransition] = useState(
    "transform 500ms ease-in-out"
  );
  const modifiedCrsList = [crs4, ...crsList, crs1]; // 시작과 끝에 추가 이미지 삽입
  const slideNextSoulsCarousel = () => {
    let newCurr = currCarousel + 1;
    setCurrCarousel(newCurr);

    // 이미지 배열의 끝에 도달한 경우, 첫 번째 이미지로 순환
    if (newCurr > crsList.length) {
      setTimeout(() => {
        setCarouselTransition("");
        setCurrCarousel(1);
      }, 500); // 500ms 후에 첫 번째 이미지로 이동
    } else {
      setCarouselTransition("transform 500ms ease-in-out");
    }
  };

  const slidePrevSoulsCarousel = () => {
    let newCurr = currCarousel - 1;
    setCurrCarousel(newCurr);

    // 이미지 배열의 처음에 도달한 경우, 마지막 이미지로 순환
    if (newCurr < 1) {
      setTimeout(() => {
        setCarouselTransition("");
        setCurrCarousel(crsList.length);
      }, 500); // 500ms 후에 마지막 이미지로 이동
    } else {
      setCarouselTransition("transform 500ms ease-in-out");
    }
  };

  useEffect(() => {
    if (currCarousel === 0 || currCarousel === crsList.length + 1) {
      setTimeout(() => {
        setCarouselTransition("transform 500ms ease-in-out");
        setCurrCarousel(currCarousel === 0 ? crsList.length : 1);
      }, 50);
    }
  }, [currCarousel, crsList.length]);

  return (
    <div className={styles.crsCover2}>
      <div className={styles.crsCover}>
        <button onClick={slidePrevSoulsCarousel}>
          <BsChevronLeft />
        </button>
        <div
          className={styles.crsBody}
          style={{
            transform: `translateX(-${currCarousel * 100}%)`,
            transition: carouselTransition,
          }}
        >
          {modifiedCrsList.map((imageSrc, index) => (
            <img key={index} src={imageSrc} alt="" />
          ))}
        </div>
        <button onClick={slideNextSoulsCarousel}>
          <BsChevronRight />
        </button>
      </div>
    </div>
  );
};

export default LLMCarousel;
