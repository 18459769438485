//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={styles.midCover}>
            <h1>미래를 선도하는 금융 AI 솔루션</h1>
            <p>최신 데이터 분석 기술 기반 맞춤형 서비스 제공으로<br />차별화된 금융 AI 솔루션을 제공합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}