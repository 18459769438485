//hooks
import { useState } from "react";
//component
import { NavmenuDD } from "./Navmenu";
//css
import styles from "../css/NavDD.module.css";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";

export default function NavDropdown(props) {

    const [dd, setDd] = useState(false);
    const ddOver = () => {
        setDd(true)
    }
    const ddOut = () => {
        setDd(false)
    }
  return (
    <li onMouseOver={ddOver} onMouseOut={ddOut} className={styles.DD_li}>
      <div className={styles.DD_lidiv}>{props.text}<span>{ dd ?  <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span></div>
      <div className={`${styles.DD_Cover} ${dd ? styles.DD_Cover2 : ''}`}>
        <NavmenuDD link={props.link[0].solutions1} title={props.title[0]}/>
        <hr/>
        <NavmenuDD link={props.link[1].solutions2} title={props.title[1]}/>
        <hr/>
        <NavmenuDD link={props.link[2].solutions3} title={props.title[2]}/>
      </div>
    </li>
  );
}
