// asset
import { BsArrowLeftCircleFill } from "react-icons/bs";
// LLMDemoVod.js 파일
import React, { forwardRef } from "react";
// import React, { forwardRef, useRef } from "react";
import styles from "../css/LLM.module.css";

const LLMDemoVod = forwardRef((props, ref) => {
  // const videoRef = useRef(null);

  // const togglePlay = () => {
  //   if (videoRef.current) {
  //     if (videoRef.current.paused) {
  //       videoRef.current.play();
  //     } else {
  //       videoRef.current.pause();
  //     }
  //   }
  // };

  return (
    <div className={styles.LLMVodCover} ref={ref}>
      <div className={styles.flexVod}>
        <div className={styles.LLMVod}>
          <div
            className={styles.CurrentVideo}
          >
            <iframe
              className={styles.ifraMe}
              src={`https://www.youtube.com/embed/S_q5uK_Lw64`}
              // width="300"
              // height="100"
              frameBorder="0"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </div>
        <div className={styles.LLMVodMent}>
          <div>
            <h1>학습의 미래를 만나다</h1>
            <h2>CodeSync</h2>
          </div>
          <p>
            LLM 기반 솔루션을 통해 어떻게
            <br />
            학습 방식을 혁신할 수 있는지
            <br />
            AI기능과 사용자 맞춤 학습 플랫폼을 직접
            <br />
            확인해보세요
          </p>
          <p>
            <BsArrowLeftCircleFill className={styles.VodArrow} /> 영상시청
          </p>
        </div>
      </div>
    </div>
  );
});

export default LLMDemoVod;
