//css
import styles from "../../CommonFile/LLM.module.css";
//asset
import SCM from "../asset/SCM.png";

export default function LLMFlow() {
  return (
    <div>
      <div className={styles.ProcessTitle}>
      {/* <h1>수요 예측 모델을 활용한 원부자제 자동 발주 시스템</h1> */}
      <h1>맞춤형 재고 관리 및 자동 발주 AI 솔루션</h1>
      {/* <h1>지능형 수요 예측 및 자재 발주 최적화 AI 솔루션</h1>
      <h1>스마트 원부자재 공급망 관리 AI 솔루션</h1> */}
        </div>
        <div className={styles.LLMProcessCover}>
          <div className={styles.flexProcess}>
            <div className={styles.flexProcessMent}>
              <h1>시스템구성</h1>
              <ul>
                <div>
                  <li>데이터 수집</li>
                  <li>데이터 분석</li>
                  <li>데이터 정제</li>
                </div>
                <div>
                  <li>AI모델링</li>
                  <li>모델 검증</li>
                  <li>예측결과 활용</li>
                </div>
              </ul>
            </div>
          </div>
          <div>
            <div className={`${styles.LLMProccess} ${styles.MDProcess}`}>
              <img src={SCM} alt="dd" />
            </div>
          </div>
        </div>
    </div>

  );
}
