//hooks
import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
import LLMFlow from './components/LLMFlow';
//asset
import ManufactorCover from './asset/ManufactorCover.png'
import { SiObservable } from "react-icons/si";
import { GiProcessor } from "react-icons/gi";
import { MdOutlineMonitorHeart } from "react-icons/md";
import { PiEqualizer } from "react-icons/pi";


export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "정밀한 불량 검출",
      body1: "데이터 분석을 통한",
      body2: "EDS 공정의 정밀한 불량 검출",
      icon :() => <SiObservable />,
    },
    {
      index: "point 02.",
      point: "프로세스 최적화",
      body1: "AI 기반의 분석으로",
      body2: "생산 공정 최적화",
      icon: () => <GiProcessor />,
    },
    {
      index: "point 03.",
      point: "품질 관리 향상",
      body1: "지속적인 데이터 모니터링으로",
      body2: "제품 품질 지속적 관리",
      icon : () => <MdOutlineMonitorHeart />,
    },
    {
      index: "point 04.",
      point: "비용절감",
      body1: "AI의 정확한 판단으로",
      body2: "생산성 확대화 비용절감",
      icon : () => <PiEqualizer />
    },
  ];
  const demoVodRef = useRef(null);
  const scrollToDemoVod = () => {
    demoVodRef.current.scrollIntoView({behavior: 'smooth'})
  };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={ManufactorCover} cdlogo={'CodeSyncLogo'} onDemoClick={scrollToDemoVod}/>
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

