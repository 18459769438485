//hooks
import { useState, useEffect } from "react";
import { ContactModal} from "../../common/components/Navmenu";
//css
import styles from "./css/Main.module.css";
//assets
import main1 from "./asset/b.png";
import main2 from "./asset/a.png";
import main3 from "./asset/c.png";
//components
import back1 from "./asset/back1.mp4";
import Nav from "../../common/components/Nav";
import Footer from "../../common/components/Footer";
import { Modal } from "../../common/components/Modal";
import MainSolutions from "./components/MainSolutions";

function typeWriter(text, i, setText) {
  if (i < text.length) {
    setText((prev) => prev + text.charAt(i));
    setTimeout(() => typeWriter(text, i + 1, setText), 100);
  }
}

export default function Main() {
  const [typedText, setTypedText] = useState([]);
  useEffect(() => {
    typeWriter(
      '생성형 AI 솔루션으로 \n 새로운 비즈니스를 경험하세요',
      0,
      setTypedText
    );
    
  }, []);
  const solutionInfo = {
    index: [1, 2, 3],
    title: [
      "맞춤형 생성AI 솔루션",
      "산업별 AI 솔루션",
      "데이터 가공•생성 서비스",
    ],
    subtitle: [
      "새로운 형태의 LLM을 만나보세요.",
      "AI솔루션으로 비즈니스 혁신을 이루세요",
      "데이터의 가치를 극대화하는 전문 서비스",
    ],
    body: [
      "생성형 AI 솔루션은 단순한 질문과 응답을 넘어서 사용자의 필요를 선제적으로 파악하고 상황에 맞는 정보를 적극적으로 제공합니다. 교육, 비즈니스, 개인 맞춤형 소프트웨어 개발 등 다양한 분야에서 사용자의 학습 및 작업 효율을 극대화합니다.",
      "산업별 맞춤 AI 솔루션은 제조, 유통, 마케팅 등 다양한 산업 분야의 요구사항에 최적화하여 개발합니다. 실시간 데이터 분석을 통해 의사결정을 지원하고 경쟁력을 강화하며, 귀사의 비즈니스 성장을 가속화합니다.",
      "데이터는 AI 시대의 중심에 있습니다. 활용되지 못한 데이터를 가공하고 증강함으로써 비즈니스 혁신을 선도할 수 있습니다. 데이터 가공 및 생성 서비스를 통해 디지털 전환으로 인한 실질적인 변화를 경험하세요.",
    ],
    img: [main1, main2, main3],
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div className={styles.Backg}>
      <Nav color="black" />
      <section className={styles.main_Visual_Cover}>
        <div className={styles.main_Visual}>
          <div>
            <video autoPlay muted playsInline loop className={styles.backgroundvideo}>
              <source src={back1} type="video/mp4" />
            </video>
            <div className={styles.main_Visualment}>
              <h1 style={{
                whiteSpace:"pre-wrap",
              }}>{typedText}</h1>
              <p>데이터 가공·생성부터 개별 기업에 맞춤 제작된 AI까지 <br />기업 변화에 필요한 모든 기능을 제공합니다. 
              </p>
              <ul className={styles.menulistB}>
                <ContactModal
                  text="문의하기"
                  backgroundColor="white"
                  color="white"
                  border="white"
                  toggleModal={toggleModal}
                />
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.bottom_Cover}>
        <section className={styles.main_Mid_Cover}>
          <div className={styles.main_Mid}>
            <div>
              {/* <h1>
                다양한 산업 영역을 아우르며
                맞춤형 AI 솔루션을 제공합니다.
              </h1> */}
              <div className={styles.main_MidB}>
                <p>{solutionInfo.title[0]}</p>
                <p>{solutionInfo.title[1]}</p>
                <p>{solutionInfo.title[2]}</p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.carousel_Cover}>
          <MainSolutions data={solutionInfo} />

        </section>
      </div>
      {isModalOpen && <Modal closeModal={toggleModal} />}
      {/* <Prac /> */}
      {/* <CrsPrac /> */}
      <Footer />
    </div>
  );
}
