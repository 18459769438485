//hooks
// import {useRef} from 'react'
//components
import Nav from "../../../common/components/Nav";
import Footer from "../../../common/components/Footer";
import LLMCover from "./components/LLM_Cover";
//asset
import FinanceCoverimg from "./asset/Financecover.png";
// import LLMDemoVod from "./components/LLM_Demo_vod";
import LLMFlow from './components/LLMFlow';
import { BsClipboardData } from "react-icons/bs";
import { GrGroup } from "react-icons/gr";
import { MdOutlineLockPerson } from "react-icons/md";
import { GiPlatform } from "react-icons/gi";

export default function LLM() {
  const midMent = [
    {
      index: "point 01.",
      point: "데이터 기반 의사결정",
      body1: "실시간 데이터 분석을 통한",
      body2: "금융 의사결정 강화",
      icon :() => <BsClipboardData />,
    },
    {
      index: "point 02.",
      point: "고객 맞춤형 서비스",
      body1: "다양한 금융 데이터를 통합",
      body2: "개인화된 서비스 구축",
      icon: () => <GrGroup />,
    },
    {
      index: "point 03.",
      point: "규제 준수와 보안",
      body1: "첨단 AI 기술을 통한 금융 규제 준수",
      body2: "고객 데이터 보호",
      icon : () => <MdOutlineLockPerson />,
    },
    {
      index: "point 04.",
      point: "통합 금융 서비스 플랫폼",
      body1: "편리한 사용자 인터페이스와",
      body2: "효율적인 데이터 관리",
      icon : () => <GiPlatform />
    },
  ];
//   const demoVodRef = useRef(null);
//   const scrollToDemoVod = () => {
//     demoVodRef.current.scrollIntoView({behavior: 'smooth'})
//   };
  return (
    <div>
      <Nav color="black" btcolor="#cfcfcf" />
      <LLMCover ment={midMent} cover={FinanceCoverimg} />
      {/* <LLMDemoVod ref={demoVodRef}/> */}
      <LLMFlow />
      <Footer />
    </div>
  );
}

