//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMidCard from "./LLM_Mid_Card"
export default function LLMMid(props) {
    return(
        <div className={styles.midCover}>
            <h1>맞춤형 AI를 위한 최적화 데이터 생성 서비스</h1>
            <p>데이터 생성 서비스는 고객사의 필요에 따라 맞춤형 데이터를 생성하는 데 중점을 두고 있습니다. 최첨단 AI 기술을 활용하여 다양한 도메인에서 필요한 특화 데이터를 생성하고 고객사의 데이터 활용 능력과 비즈니스 가치를 극대화를 목표로 합니다.</p>
            <div className={styles.cardCover}>
                <LLMMidCard ment={props.ment[0]}/>
                <LLMMidCard ment={props.ment[1]}/>
                <LLMMidCard ment={props.ment[2]}/>
                <LLMMidCard ment={props.ment[3]}/>
            </div>
        </div>
    )
}