//css
import styles from "../../CommonFile/LLM.module.css";
import LLMMid from "./LLM_Mid";
//component
export default function LLMCover(props) {
  return (
    <div className={styles.LLMCover}>
      <div className={styles.LLMImgCover}>
        <div className={`${styles.LLMImgCoverc} ${styles.FNImgCoverc}`}></div>
        <img src={props.cover} alt="배경 이미지" />
        <div className={`${styles.LLMCoverMent} ${styles.FNCoverMent}`}>
          {/* <img src={props.cdlogo} alt="배경 로고" className={styles.coverLogo} />
          &nbsp;<span>코드싱크</span> */}
          <h3>금융 서비스 혁신을 위한 AI 솔루션</h3>
          <p>
            금융 AI 솔루션은 데이터 중심의 접근 방식으로 금융 서비스의 디지털
            전환을 지원합니다. 데이터 분석과 모델링에 중점을 두어, 금융 서비스의
            더 나은 고객 경험을 제공하고, 시장 변화에 신속하게 대응할 수 있게
            합니다.
          </p>
          {/* <div className={styles.demolinkcover}>
            <a href="#!" onClick={props.onDemoClick}>Demo 보러가기 &nbsp; &gt;</a>
          </div> */}
        </div>
      </div>
      <LLMMid ment={props.ment} />
    </div>
  );
}
